class Resource {
  constructor(path, http) {
    this.uri = `api/${path}`
    this.http = http
  }

  list(opt = {}) {
    return this.http.get(this.uri, opt)
  }

  show(id, opt = {}) {
    return this.http.get(`${this.uri}/${id}`, opt)
  }

  store(data, opt = {}) {
    return this.http.post(this.uri, data, opt)
  }

  update(id, data, opt = {}) {
    return this.http.put(`${this.uri}/${id}`, data, opt)
  }

  destroy(id, opt = {}) {
    return this.http.delete(`${this.uri}/${id}`, opt)
  }
}

export default Resource
