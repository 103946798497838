import ChallengeRequest from '@/@api/challenge'
import { ref, computed } from '@vue/composition-api'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const challenges = ref([])

export default function useChallenge() {
  // Use toast
  const toast = useToast()

  const refLangListTable = ref(null)

  // const challenges = ref([])

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    // { label: 'ISO', key: 'iso', sortable: true },
    {
      label: 'Reto',
      key: 'name',
      sortable: true,
      thStyle: { width: '100%' },
    },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalChallenges = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refLangListTable.value ? refLangListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalChallenges.value,
    }
  })

  const refetchData = () => {
    refLangListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchChallenges = (ctx, callback) => {
    ChallengeRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(challenge => ({ ...challenge, loading: false })))
        totalChallenges.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching challenges list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function fetchChallengesSelector(isMemo = false) {
    if (isMemo && challenges.value.length) {
      return Promise.resolve(challenges.value)
    }

    try {
      const { data } = await ChallengeRequest.select()

      const list = data.data.map(challenge => ({
        id: challenge.id,
        value: challenge.iso?.toUpperCase() || challenge.id,
        label: challenge.name,
      }))

      challenges.value = list

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function challengeStore(body) {
    try {
      const { data } = await ChallengeRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function challengeUpdate(id, body) {
    try {
      const { data } = await ChallengeRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function challengeDelete(id) {
    try {
      const { data } = await ChallengeRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  fetchChallengesSelector(true).then(list => {
    challenges.value = list
  })

  const resolveIsoLang = iso => {
    if (!iso) return ''

    const dic = challenges.value.reduce((acc, challenge) => {
      acc[challenge.value] = challenge.label

      return acc
    }, {})

    return dic[iso] ?? iso
  }

  return {
    challenges,
    fetchChallenges,
    fetchChallengesSelector,
    tableColumns,
    perPage,
    currentPage,
    totalChallenges,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLangListTable,

    resolveIsoLang,

    refetchData,
    challengeStore,
    challengeUpdate,
    challengeDelete,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
